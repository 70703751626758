import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { DocumentAPI } from "../../apis/DocumentAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import { uploadFileStoreFactory } from "../_stores/uploadFileStore";

export const fetchListThunk = fetchListStoreFactory(
  "document-slice/list",
  DocumentAPI.getAll,
  "documentSlice"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "document-slice/get",
  DocumentAPI.getById,
  "documentSlice"
);
export const deleteThunk = deleteStoreFactory(
  "document-slice/delete",
  DocumentAPI.delete,
  "documentSlice"
);
export const saveThunk = saveStoreFactory(
  "document-slice/save",
  DocumentAPI.save,
  "documentSlice",
);
export const updateThunk = updateStoreFactory(
  "document-slice/update",
  DocumentAPI.update,
  "documentSlice",
);

export const uploadFileThunk = uploadFileStoreFactory(
  "user-admin/upload",
  DocumentAPI.uploadFile,
  "userAdmin"
);

export const modelSlice = createSlice({
  name: "document-slice",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setHasTemplate: (state, action) => {
      return { ...state, model: { ...state.model, hasTemplate: action.payload } };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
  },
});
