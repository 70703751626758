import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/slice";
import { AuthAPI } from "../apis/AuthAPI";
import { modelSlice as userAdminSlice } from "./userAdminStore/slice";

import { modelSlice as editProfileSlice } from "./editProfileStore/slice";

import { modelSlice as loginSlice } from "./loginStore/slice";
import { modelSlice as dashboardSlice } from "./dashboardStore/slice";

import { modelSlice as clientSlice } from "./ClientStore/slice";
import { modelSlice as licensedSlice } from "./LicensedStore/slice";
import { modelSlice as fieldSlice } from "./FieldStore/slice";
import { modelSlice as productSlice } from "./ProductStore/slice";
import { modelSlice as phaseSlice } from "./PhaseStore/slice";
import { modelSlice as processSlice } from "./ProcessStore/slice";
import { modelSlice as jurisprudenceSlice } from "./JurisprudenceStore/slice";
import { modelSlice as matterSlice } from "./MatterStore/slice";
import { modelSlice as documentSlice } from "./DocumentStore/slice";
import { modelSlice as keyElementSlice } from "./KeyElementStore/slice";
import { modelSlice as emailTemplateSlice } from "./EmailTemplateStore/slice";

import { setTokenAndMe, logout } from "./auth/actions";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    login: loginSlice.reducer,
    editProfile: editProfileSlice.reducer,
    userAdmin: userAdminSlice.reducer,
    dashboard: dashboardSlice.reducer,
    //
    clientSlice: clientSlice.reducer,
    licensedSlice: licensedSlice.reducer,
    fieldSlice: fieldSlice.reducer,
    productSlice: productSlice.reducer,
    phaseSlice: phaseSlice.reducer,
    processSlice: processSlice.reducer,
    jurisprudenceSlice: jurisprudenceSlice.reducer,
    matterSlice: matterSlice.reducer,
    keyElementSlice: keyElementSlice.reducer,
    documentSlice: documentSlice.reducer,
    emailTemplateSlice: emailTemplateSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const getAsyncStorage = () => {
  console.log("store::getAsyncStorage");
  return (dispatch:any) => {
    let token = localStorage.getItem("token");

    if (token) {
      AuthAPI.me(token)
        .then((result) => {
          dispatch(setTokenAndMe({ token: token, me: result }));
        })
        .catch((e) => {
          console.log(
            `Error getAsyncStorage::AuthAPI::me ${JSON.stringify(e)}`
          );
          if (
            (e.errors !== undefined && e.errors[0].status === 401) ||
            (e.errors !== undefined && e.errors[0].status === 403) ||
            e.status === 401 ||
            e.status === 403 ||
            e === 403
          ) {
            console.log("Loggin out....");
            dispatch(logout());
          }
        });
    } else {
      console.log(`Error getAsyncStorage::getItem::result`);
      dispatch(logout());
    }
  };
};

store.dispatch(getAsyncStorage());
