import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { PhaseAPI } from "../../apis/PhaseAPI";

import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import {
  addSaveFieldStoreFactoryReducer,
  saveFieldStoreFactory,
} from "../_stores/saveFieldStore";
import { addFetchListFieldStoreFactoryReducer, fetchListFieldStoreFactory } from "../_stores/fetchListFieldStore";

export const fetchListThunk = fetchListStoreFactory(
  "phase-slice/list",
  PhaseAPI.getAll,
  "phaseSlice"
);
export const fetchCoverPhasesThunk = fetchListFieldStoreFactory(
  "phase-slice/list-cover-phases",
  PhaseAPI.getCoverPhases,
  "productSlice",
  'coverProducts'
);
export const fetchIdThunk = fetchIdStoreFactory(
  "phase-slice/get",
  PhaseAPI.getById,
  "phaseSlice"
);
export const deleteThunk = deleteStoreFactory(
  "phase-slice/delete",
  PhaseAPI.delete,
  "phaseSlice"
);
export const saveThunk = saveStoreFactory(
  "phase-slice/save",
  PhaseAPI.save,
  "phaseSlice",
);
export const updateThunk = updateStoreFactory(
  "phase-slice/update",
  PhaseAPI.update,
  "phaseSlice",
);

export const importFields = saveFieldStoreFactory(
  "phase-slice/import-fields",
  PhaseAPI.importFields,
  "phaseSlice",
  "fieldsJSON"
);

export const modelSlice = createSlice({
  name: "phase-slice",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setDescription: (state, action) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },
    setOrder: (state, action) => {
      return { ...state, model: { ...state.model, order: action.payload } };
    },
    setType: (state, action) => {
      return { ...state, model: { ...state.model, type: action.payload } };
    },
    setHasPreview: (state, action) => {
      return {
        ...state,
        model: { ...state.model, hasPreview: action.payload },
      };
    },
    setKey: (state, action) => {
      return { ...state, model: { ...state.model, key: action.payload } };
    },
    setContentType: (state, action) => {
      return {
        ...state,
        model: { ...state.model, contentType: action.payload },
      };
    },
    addText: (state, action) => {
      const item = { ...action.payload };
      state.model.text.push(item);
    },
    removeText: (state, action) => {
      state.model.text.splice(action.payload.index, 1);
    },
    addDocument: (state, action) => {
      const item = { ...action.payload };
      state.model.documents.push(item);
    },
    removeDocument: (state, action) => {
      const idx = state.model.documents.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.documents.splice(idx, 1);
    },
    addEmail: (state, action) => {
      const item = { ...action.payload };
      state.model.emails.push(item);
    },
    removeEmail: (state, action) => {
      const idx = state.model.emails.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.emails.splice(idx, 1);
    },
    setText: (state, action) => {
      return {
        ...state,
        model: { ...state.model, Texts: action.payload },
      };
    },
    setProduct: (state, action) => {
      return { ...state, model: { ...state.model, product: action.payload } };
    },
    setFieldsJSON: (state, action) => {
      return { ...state, fieldsJSON: action.payload };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    setQuery: listReducers.setQuery,

    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListFieldStoreFactoryReducer(builder, fetchCoverPhasesThunk)
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    addSaveFieldStoreFactoryReducer(builder, importFields);
  },
});
