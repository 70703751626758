import SidebarItem from "../SidebarItem";

// icons
import {
  FaUsers,
  FaUserAlt,
  FaQuestion,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa";
import {
  MdOutlineQuestionAnswer,
  MdQuestionAnswer,
  MdListAlt,
  MdList,
  MdTask,
  MdDashboard,
  MdTimer,
} from "react-icons/md";

export default function AdminMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<MdDashboard />}
        title="Dashboard"
        href="/"
        {...{ shrunk }}
      />
      <SidebarItem icon={<FaUsers />} title="Jurídico" {...{ shrunk }}>
        <SidebarItem
          icon={<FaUserPlus />}
          title="Matérias"
          href="/matters"
          {...{ shrunk }}
        />
         <SidebarItem
          icon={<FaUserPlus />}
          title="Documentos"
          href="/documents"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Emails"
          href="/emailtemplates"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Elementos-chave"
          href="/key-elements"
          {...{ shrunk }}
        />

        <SidebarItem
          icon={<FaUserPlus />}
          title="Jurisprudencias"
          href="/jurisprudences"
          {...{ shrunk }}
        />
      </SidebarItem>
      <SidebarItem icon={<FaUsers />} title="Produto" {...{ shrunk }}>
        <SidebarItem
          icon={<FaUserPlus />}
          title="Produtos"
          href="/products"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Fases"
          href="/phases"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Campos"
          href="/fields"
          {...{ shrunk }}
        />
      </SidebarItem>
      <SidebarItem icon={<FaUsers />} title="Pessoas" {...{ shrunk }}>
        <SidebarItem
          icon={<FaUserPlus />}
          title="Usuários"
          href="/users"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Clientes"
          href="/clients"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Licenciados"
          href="/licenseds"
          {...{ shrunk }}
        />
      </SidebarItem>
      <SidebarItem
        icon={<FaUserPlus />}
        title="Processos"
        href="/processes"
        {...{ shrunk }}
      />
    </>
  );
}
